@use 'sass:map';
@import '../../styles/variables';
@import '~bootstrap/scss/mixins';

$_header-height: 100px;
$_sidebar-width: 240px;
$_zindex-header: 100;

////////////////////////////////////////////////////////////////////
// page

.page {
  display: flex;
  //padding-top: $_header-height;
  min-height: 100vh;
  flex-direction: column;
  //background-color: $gray-100;
}

.page--has-header {
  padding-top: $_header-height;
}

////////////////////////////////////////////////////////////////////
// page__header

.page__header {
  background-color: #ffffff;
  border-bottom: 1px solid $gray-300;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $_zindex-header;

  .navbar {
    width: 100%;
  }

  .nav__vertical-divider {
    height: 25px;
    width: 0;
    margin: 0 1rem;
    overflow: hidden;
    border-left: 2px solid #e9ecef;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

////////////////////////////////////////////////////////////////////
// page__center

.page__center {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  height: 100%;

  &--has-header,
  &__center {
    min-height: calc(100vh - #{$_header-height});
  }
}

////////////////////////////////////////////////////////////////////
// page__main

.page__main {
  position: relative; // *important: page loading will be {position: absolute}
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  //min-height: 100vh;
}

////////////////////////////////////////////////////////////////////
// page__sidebar
.page__sidebar {
  min-height: calc(100vh - #{$_header-height});

  &__inner {
    position: fixed;
    top: $_header-height;
    background-color: #ffffff;
    z-index: 99;
    height: calc(100vh - #{$_header-height});
    overflow-y: auto;
    overflow: hidden;

    &--private {
      top: 0;
      height: 100%;
      overflow-y: auto;
    }
  }

  &--left {
    border-right: 1px solid $gray-200;
  }

  &--right {
    border-left: 1px solid $gray-200;
  }

  &--overlay {
    position: fixed;
    z-index: 1030;
    opacity: 1;
    visibility: visible;

    &--closed {
      opacity: 1;
      visibility: hidden;
    }
  }
}

////////////////////////////////////////////////////////////////////
// page__footer
.page__footer {
  margin-top: auto;
  padding: 2rem 0;
  background-color: #fff;
  border-top: 1px solid $gray-300;
  color: rgba(0, 0, 0, 0.5);

  ul {
    padding: 0;
    margin-bottom: 0;
    list-style: none;
  }

  a {
    color: rgba(0, 0, 0, 0.5);
    line-height: 2;
    white-space: nowrap;
    &.active,
    &:hover,
    &:active {
      color: $primary;
    }
  }

  a[class='active'] {
    color: $primary;
  }
}

.page__backdrop {
  top: 0;
  left: 0;
  z-index: 1020; // 999
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

////////////////////////////////////////////////////////////////////
// Notifications

.notifications__badge {
  position: absolute;
  border-radius: 1rem;
  background-color: $danger;
  line-height: 1rem;
  color: $white;
  padding: 0 5px;
  font-size: $font-size-sm;
  font-weight: bold;
  top: 0;
  left: 55%;
}

.notifications__list {
  width: 500px;
  margin-top: 8px;
  padding: 0 !important;
  z-index: 99999;
  @media screen and (max-width: map.get($grid-breakpoints, 'sm')) {
    width: 350px;
  }
}

.notifications__item {
  padding: 12px 16px;
  white-space: normal;
  display: flex;
  align-items: center;
  // align-items: flex-start;

  .notification__icon {
    margin: 0rem 12px;
  }

  .notification__icon--error {
    color: #e04552 !important;
  }

  .notification__icon--success {
    color: $success !important;
  }

  .notification__icon--password {
    color: #2146da !important;
  }

  .notification__icon--enabled {
    color: #efbc21 !important;
  }

  .notification__icon--disabled {
    color: #cec6c7 !important;
  }

  .notification__icon--cert {
    color: #2bcac9 !important;
  }

  .notification__icon--enquiry {
    color: #2bcac9 !important;
  }

  .notification__icon--company {
    color: #82c91e !important;
  }

  .notification__icon--warning {
    color: $warning !important;
  }

  .notifications__item--content {
    padding: 0px 12px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 4px;

    .notifications__item--content--badge {
      font-size: 10px !important;
      font-weight: 500;
      color: $dark;
      background-color: rgba($color: $secondary, $alpha: 0.3);
      width: fit-content;
    }

    .notifications__item--content--message {
      font-size: 14px;
      margin-bottom: 0;
      font-weight: 400;
    }

    .notifications__item--content--date {
      font-size: 11px;
      line-height: 16px;
      font-weight: 400;
    }
  }

  .notifications__item--actions {
    margin: 0rem 12px;
  }
}

.notifications__item--empty {
  min-height: 90px;
  text-align: center;
  padding: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.notifications__item--unread {
  background-color: rgba($color: $primary, $alpha: 0.1);
  cursor: pointer;
}

.notifications__item--read {
  background-color: $white;
  cursor: pointer;
}

.notifications__text {
  flex-grow: 1;

  .notifications__text--message {
    line-height: 1.2;
  }

  .notifications__text--date {
    font-size: $font-size-sm;
    text-align: left;
    color: $gray-600;
    font-style: italic;
  }
}

.search__type {
  width: 12rem !important;
}

.search__forms {
  // width: 45rem !important;
  margin-left: 1rem;
}

.form-control-select {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-left: 1rem;
  width: 9rem;
}

.form-control__select {
  background-color: white !important;
  border-color: #ced4da !important;
  color: black !important;
  width: 10rem;

  &:focus {
    background-color: white !important;
    border-color: #0f579f !important;
    color: black !important;
  }

  &:hover {
    background-color: #94979b;
    border-color: #07437e;
    color: black;
  }
}

.form-control-search {
  border-radius: 0 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
}

//  Captcha Modal

.captcha__modal-body {
  max-width: 18%;
  border-radius: 30px;
}

.navigation__link--dark {
  color: $light !important;
  $muted: rgba($light, 0.8);

  &--active {
    border-bottom: 2px solid $light;

    &:hover {
      border-bottom: 2px solid $muted;
    }
  }

  &:hover {
    color: $muted !important;
  }
}

.navigation__link--light {
  color: $dark;

  &--active {
    color: $primary;
    border-bottom: 2px solid $primary;
  }
}

.dropdown__link--active {
  color: $primary !important;
  background-color: rgba($primary, 0.1) !important;
}

////////////////////////////////////////////////////////////////////
// PrivateNav
.private__nav__search__container {
  // width: 60%;
  max-width: 50rem;
  flex-grow: 1;
  margin-right: 1rem;
}

.private-search__input-group {
  position: static !important;
  border: 15px solid rgba($color: $primary, $alpha: 0.1) !important;
  border-radius: 50px !important;

  @include media-breakpoint-down(sm) {
    border: none !important;
  }
}

.private-search__dropdown {
  width: 12rem !important;
  color: $text-muted !important;

  border-right: 0 !important;
  border-radius: 50px 0px 0px 50px !important;
  border-color: #0077c0 !important;
  background-color: white !important;

  // states
  &:focus {
    background-color: white !important;
    border-color: #0f579f !important;
    color: $text-muted !important;
  }

  &:hover {
    background-color: white !important;
    border-color: #0f579f !important;
    color: $text-muted !important;
  }

  &--item {
    padding-top: 5px !important;
    padding-bottom: 5px !important;

    &:hover {
      color: $primary !important;
      background-color: rgba($color: $primary, $alpha: 0.1) !important; // 0.5
    }
  }
}
